$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

@import '~animate.css/animate.css';
@import '~air-datepicker/dist/css/datepicker.css';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap/dist/css/bootstrap-theme.css';
@import '~bootstrap-select/dist/css/bootstrap-select.css';
@import '../vendor/jquery.baraja/baraja.css';
@import '~jquery-ui-themes/themes/eggplant/jquery-ui.css';
@import '~jquery-ui.combobox/lib/jquery-ui.combobox.css';
@import '~jquery-ui-multiselect-widget/css/jquery.multiselect.css';
@import '~jquery-ui-multiselect-widget/css/jquery.multiselect.filter.css';
@import '~toastr/build/toastr.css';
